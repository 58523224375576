@use 'common'

.section
	width: 100%
	position: relative
	h2
		margin-bottom: 60px
		text-align: center
		color: #fff
	@media(max-width: 768px)
		h2
			margin-bottom: 40px

.bg
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: calc(100% - 100px)
	@media(max-width: 768px)
		height: calc(100% - 50px)
	> div
		position: absolute!important
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover
