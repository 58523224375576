@use 'common'

.box
	box-sizing: border-box
	position: relative
	width: calc(50% - 20px)
	margin: 10px
	position: relative
	padding-top: calc(50% - 20px)
	text-align: left
	&.smaller
		width: calc(25% - 20px)
		padding-top: calc(25% - 20px)
		@media(max-width: 1100px)
			width: calc(50% - 20px)
			padding-top: calc(50% - 20px)
		@media(max-width: 768px)
			width: 100%
			margin: 10px 0
			padding-top: 100%
	@media(max-width: 1100px)
		width: calc(50% - 20px)
		padding-top: calc(50% - 20px)
	@media(max-width: 768px)
		width: 100%
		margin: 10px 0
		padding-top: 100%

.image
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	opacity: 0
	transition: opacity .5s ease
	> div
		position: absolute!important
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover
	&.active
		opacity: 1

.content
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	padding: 20px
	z-index: 1
	color: #fff
	h3
		font-size: 25px
		font-family: common.$regular
		display: none
		&.active
			display: inline-block
