@use 'common'

.cta
	overflow: hidden
	background-color: common.$yellow
	position: relative
	transition: transform .35s ease
	&:hover
		transform: translateY(-10px)
	&.auto
		max-width: 600px
		margin: 0 auto
		.inner
			position: relative
			height: auto
	&.transparent
		background-color: transparent
		text-align: center
		max-width: 450px
	&.offset
		transform: translateY(100px)
		z-index: 1
		&:hover
			transform: translateY(90px)
		@media(max-width: 768px)
			transform: translateY(50px)
			&:hover
				transform: translateY(50px)
	&.bigger
		.inner
			h3
				font-size: 45px
				margin-bottom: 20px
			@media(max-width: 768px)
				article
					margin-top: 16px
				h3
					font-size: 35px
					margin: 0



.inner
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	padding: 30px
	box-sizing: border-box
	display: flex
	flex-direction: column
	justify-content: flex-end
	h3
		font-size: 30px
		font-family: common.$regular
		margin-bottom: auto
		justify-self: flex-start
	article
		font-size: 20px
	a
		font-size: 20px
		text-decoration: none
		font-family: common.$bold
		text-transform: uppercase
		-webkit-font-smoothing: antialiased
		&:hover
			svg
				transform: translateX(2px)
		&:after
			content: ''
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			cursor: pointer
		svg
			margin-left: 10px
			height: 15px
			transition: transform .35s ease
	@media(max-width: 768px)
		padding: 15px
		position: relative
		h3
			font-size: 25px
		article
			font-size: 18px
			margin-top: 50px
