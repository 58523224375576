@use 'common'

.header
	width: 100%
	position: relative
	height: 95vh
	min-height: 700px
	max-height: 900px
	overflow: hidden
	display: flex
	flex-direction: column
	padding-bottom: 70px
	&:after
		content: ''
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: rgba(0, 0, 0, 0.2)
	> div:first-child
		margin-top: auto
		flex-shrink: 0
		width: 100%
	> div:last-child, video
		position: absolute!important
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover
	@media(max-width: 768px)
		padding-bottom: 30px
		min-height: 600px
		max-height: 80vh

.inner
	width: 100%
	height: 100%
	z-index: 1
	position: relative
	h1
		max-width: 600px
		text-transform: uppercase
		margin-bottom: 10px
		line-height: 1em
	article, p
		font-size: 25px
		color: #fff
		max-width: 530px
		margin-bottom: 20px
	a + a
		margin-left: 20px
	@media(max-width: 768px)
		width: 100%
		article, p
			font-size: 20px
		a + a
			margin-left: 0
			margin-top: 20px
