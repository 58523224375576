@use 'common'

.section
	width: 100%
	position: relative
	h2
		text-align: center
		margin-bottom: 60px
	@media(max-width: 768px)
		h2
			margin-bottom: 40px
.slider
	position: relative

.sliderInner
	position: relative
	display: flex
	overflow: hidden

.single
	opacity: 1
	width: 20%
	position: relative
	overflow: hidden
	cursor: pointer
	&:hover:before
		opacity: .6
	&:hover:after
		opacity: 0
	&:after
		content: ''
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)
		z-index: 1
	&:before
		opacity: 0
		content: ''
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background-color: common.$yellow
		z-index: 1
		transition: opacity .35s ease
		z-index: 1

.content
	z-index: 2
	position: relative
	color: #fff
	height: 100%
	position: absolute
	top: 0
	left: 0
	width: 100%
	padding: 20px
	display: flex
	flex-direction: column
	justify-content: flex-end
	h3
		font-size: 20px
		margin-bottom: .2em
	p
		margin: 0
		font-size: 18px

.image
	padding-top: 150%
	width: 100%
	position: relative
	> div
		position: absolute!important
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover

@media(max-width: 768px)
	.sliderInner
		overflow: visible
