@use 'common'

.section
	background-color: common.$yellow
	h2
		text-align: center
		margin: 0 auto
		margin-bottom: 60px
		color: #000
		max-width: 600px
	@media(max-width: 768px)
		h2
			margin-bottom: 40px

.inner
	max-width: 600px
	margin: 0 auto
	p
		color: #000
		text-align: center

.textarea
	border-bottom: none
	resize: vertical

.textareaWrapper
	border-bottom: 2px solid #000

.form
	color: #000
	width: 100%
	display: flex
	flex-direction: column
	&.sending
		opacity: .5
		pointer-events: none
	label
		font-family: common.$mager
		font-size: 20px
		position: relative
		cursor: pointer
		span
			margin-left: 10px
			width: 20px
			height: 20px
			display: inline-block
			background-color: #000
			position: relative
			transform: translateY(2px)
			svg
				position: absolute
				top: 4px
				left: 4px
				width: 12px
				height: 12px
				color: common.$yellow
				transform: rotate(45deg)
	.file
		display: none
	input, textarea
		background-color: transparent
		width: 100%
		border: none
		font-size: 20px
		font-family: common.$mager
		color: #000
		padding: 0 0 10px 0
		-webkit-appearance: none
		border-radius: 0
		&:focus
			outline: none
			//border: none
		&::placeholder
			color: #000
	input
		border-bottom: 2px solid #000
	input + input, textarea, button, label
		margin-top: 40px
	button
		font-family: common.$bold
		color: #000
		font-size: 20px
		background-color: transparent
		border: none
		cursor: pointer
		svg
			//margin-left: 10px
			transition: transform .35s ease
		&:hover
			svg
				transform: translateX(2px)
