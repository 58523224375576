@use 'common'

.box
	box-sizing: border-box
	position: relative
	width: calc(25% - 20px)
	margin: 10px
	position: relative
	padding-top: calc(25% - 20px)
	background-color: common.$yellow
	text-align: left
	transition: transform .35s ease
	&.grey
		background-color: common.$grey
	&:hover
		transform: translateY(-10px)
	@media(max-width: 1100px)
		width: calc(50% - 20px)
		padding-top: calc(50% - 20px)
	@media(max-width: 768px)
		width: 100%
		margin: 10px 0
		padding-top: 100%

.content
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	padding: 20px
	z-index: 1
	color: #000
	display: flex
	flex-direction: column
	h3
		font-size: 25px
		font-family: common.$regular
	a
		margin-top: auto
		justify-self: flex-end
		font-size: 19px
		text-decoration: none
		text-transform: uppercase
		&:hover
			svg
				transform: translateX(2px)
		&:after
			content: ''
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			cursor: pointer
		svg
			margin-left: 10px
			height: 15px
			transition: transform .35s ease
