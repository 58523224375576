@use 'sass:math'
@use 'common'

.collapsible

	--cui-collapsible-private-transition-duration: var(--cui-collapsible-transition-duration, .3s)
	--cui-collapsible-transition-timing-function: ease
	--cui-collapsible-content-height: auto
	visibility: hidden
	opacity: 0
	display: flex
	transition-property: height, width, opacity, visibility
	transition-timing-function: var(--cui-collapsible-transition-timing-function)
	transition-duration: var(--cui-collapsible-private-transition-duration)

	@media (prefers-reduced-motion: reduce)
		// 1 frame at 60 fps
		--cui-collapsible-private-transition-duration: #{math.div(1s, 60)}
		--cui-collapsible-transition-timing-function: step-end

	&.is_expanded
		visibility: visible
		opacity: 1

	&.is_transitioning
		overflow: hidden

	.content
		width: 100%

	&.view_topInsert,
	&.view_bottomInsert
		height: 0

		&.is_expanded
			height: var(--cui-collapsible-content-height)

	&.view_topInsert
		align-items: flex-end

	&.view_bottomInsert
		align-items: flex-start

	&.view_fade,
	&.view_leftInsert,
	&.view_rightInsert
		align-items: flex-start
		height: 0
		transition-timing-function: step-end, var(--cui-collapsible-transition-timing-function), var(--cui-collapsible-transition-timing-function), var(--cui-collapsible-transition-timing-function)

		&.is_expanded
			height: var(--cui-collapsible-content-height)
			transition-timing-function: step-start, var(--cui-collapsible-transition-timing-function), var(--cui-collapsible-transition-timing-function), var(--cui-collapsible-transition-timing-function)

	&.view_leftInsert,
	&.view_rightInsert
		.content
			transition-property: transform
			transition-timing-function: var(--cui-collapsible-transition-timing-function)
			transition-duration: var(--cui-collapsible-private-transition-duration)
			transform: translateX(-100%)

	&.view_rightInsert
		.content
			transform: translateX(100%)

	&.view_leftInsert,
	&.view_rightInsert
		&.is_expanded .content
			transform: none
