@use 'common'

.popup
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	background: rgba(0, 0, 0, 0.66)
	display: flex
	justify-content: center
	align-items: center
	z-index: 5
	opacity: 0
	pointer-events: none
	transition: opacity .35s ease
	&.active
		opacity: 1
		pointer-events: auto
	&.onlyImage
		.image
			width: 100%
			padding-top: 178%
			height: auto
			@media(max-width: 768px)
				width: 100%
				height: 400px
		.inner, .wrap
			max-width: 300px
			height: auto
			@media(max-width: 480px)
				max-width: 300px

.close
	background-color: transparent
	padding: 0
	color: #fff
	border: none
	margin-left: auto
	margin-bottom: 20px
	cursor: pointer

.wrap
	opacity: 1
	max-height: 80vh
	width: 80%
	height: 700px
	display: flex
	flex-direction: column
	@media(max-width: 768px)
		height: auto

.inner
	width: 100%
	height: 100%
	display: flex
	@media(max-width: 768px)
		flex-direction: column-reverse

.content
	background-color: #fff
	padding: 50px
	text-align: left
	overflow-y: auto
	&::-webkit-scrollbar
		//display: none
		height: 100%
		-webkit-appearance: none
		width: 5px
		-webkit-overflow-scrolling: auto
	&::-webkit-scrollbar-track
		background-color: common.$yellow
		height: 5px !important
	&::-webkit-scrollbar-thumb
		background-color: #000
	h2
		text-align: left!important
		margin-bottom: .2em!important
	article
		font-size: 20px
		p:first-child
			font-size: 25px
			margin-bottom: 1em
	@media(max-width: 768px)
		padding: 20px
		width: 100%
		max-height: 40vh

		h2
			font-size: 24px
		article
			font-size: 16px
			p:first-child
				font-size: 18px

.image
	max-height: 80vh
	position: relative
	width: 40%
	flex-shrink: 0
	//width: 30%
	height: 100%
	display: flex
	align-items: center
	justify-content: center
	a
		background-color: common.$yellow
		z-index: 2
		display: block
		width: 60px
		height: 60px
		display: flex
		align-items: center
		justify-content: center
		transition: transform .35s ease
		&:after
			content: ''
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			z-index: 10
		&:hover
			transform: scale(.9)
	@media(max-width: 768px)
		width: 100%
		height: 200px
	> div
		position: absolute!important
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover
