@use 'common'

.section
	width: 100%
	h2
		margin-bottom: 30px
	@media(max-width: 768px)
		h2
			//margin-bottom: 40px

.inner
	display: flex
	align-items: center
	justify-content: space-between
	@media(max-width: 768px)
		flex-direction: column

.content
	width: 45%
	a
		margin: 0 20px 20px 0
	@media(max-width: 768px)
		width: 100%
		h2
			text-align: center
			margin-bottom: 25px

.image
	width: 50%
	padding-top: 50%
	position: relative
	&.smaller
		width: 38%
		padding-top: 38%
	> div
		position: absolute!important
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover
	@media(max-width: 768px)
		width: 100%
		padding-top: 100%
		&.smaller
			width: 100%
			padding-top: 100%
