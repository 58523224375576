@use 'common'

.nav
	width: 100%
	position: absolute
	top: 0
	width: 100%
	left: 0
	z-index: 5
	&.invert
		color: #000
		a
			color: #000
	@media(max-width: 1100px)
		padding: 30px 0
		position: fixed
		top: 0
		left: 0
		width: 100%
		background-color: common.$yellow
		box-sizing: border-box
		transform: translateY(-100%)
		transition: transform .35s ease
		&.active
			transform: translateY(0)
			.open
				transform: translate(-50%, -100%)
	@media(max-width: 480px)
		padding: 15px 0 30px

.inner
	display: flex
	justify-content: space-between
	@media(max-width: 1100px)
		flex-direction: column
		justify-content: flex-start
		position: relative
		background-color: common.$yellow
		z-index: 5

.left
	display: flex
	align-items: center
	> a
		color: #fff
	@media(max-width: 1100px)
		flex-direction: column
		align-items: flex-start
		> a
			color: #000

.menu
	list-style: none
	display: flex
	align-items: center
	padding: 0
	li
		margin-left: 30px
		&.current
			a
				font-weight: 700
		a
			display: inline-block
			color: #fff
			text-decoration: none
			font-family: common.$mager
			font-size: 20px
			font-weight: 300
	@media(max-width: 1100px)
		margin-top: 40px
		flex-direction: column
		align-items: flex-start
		li
			margin: 0 0 15px 0
			a
				color: #000

.right
	display: flex
	align-items: center
	a
		color: #fff
		text-decoration: none
		font-family: common.$mager
		font-size: 20px
	ul
		padding: 0
		list-style: none
		display: flex
		align-items: center
		li
			margin-right: 10px
			a
				display: block
				background-color: common.$yellow
				border-radius: 50%
				width: 35px
				height: 35px
				display: flex
				align-items: center
				justify-content: center
				svg
					width: 17px
					height: 17px
	@media(max-width: 1100px)
		> a
			position: absolute
			top: .5rem
			right: 0
			color: #000
		ul
			li
				margin-right: 25px
				a
					width: 20px
					height: 20px
					border-radius: 0
					svg
						width: 20px
						height: 20px
			li:nth-child(1)
				a
					width: 25px
					height: 25px
					svg
						width: 25px
						height: 25px
			li:nth-child(4)
				a
					width: 18px
					height: 18px
					svg
						width: 18px
						height: 18px

.close
	display: none
	@media(max-width: 1100px)
		margin-top: 40px
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		background-color: transparent
		border: none
		cursor: pointer
		color: #000
		svg
			transform: rotate(-90deg)
			margin-bottom: 10px
		span
			font-size: 20px
			color: #000
			font-family: common.$mager
			font-weight: 700
		&:focus
			outline: none
			border: none

.open
	display: none
	@media(max-width: 1100px)
		display: block
		position: absolute
		bottom: 0
		left: 50%
		transform: translate(-50%, 100%)
		font-size: 20px
		color: #000
		font-family: common.$mager
		background-color: common.$yellow
		border: none
		padding: 15px 30px
		font-weight: 700
		cursor: pointer
		transition: transform .35s ease
		&:focus
			outline: none
			border: none
