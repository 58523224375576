@use 'common'

.section
	padding-top: 150px!important
	width: 100%
	@media(max-width: 768px)
		padding-top: 100px!important
	h1
		max-width: 500px
		text-transform: uppercase
		margin-bottom: 10px
		line-height: 1em

.inner
	margin-top: 100px
	@media(max-width: 768px)
		margin-top: 50px
	ul
		list-style: none
		padding: 0
		margin: 0
		width: 100%
		border-top: 1px solid #000
		li
			display: flex
			align-items: flex-start
			width: 100%
			justify-content: space-between
			padding: 15px 10px
			border-bottom: 1px solid #000
			position: relative
			&:hover
				background-color: #e2e2e2
			h4, p, a
				width: calc(25% - 20px)
				font-size: 20px
				font-family: common.$mager
				margin: 0
			p
				opacity: .5
			a
				text-align: right
				cursor: pointer
				text-decoration: none
				font-family: common.$bold
				text-transform: uppercase
				&:hover
					svg
						transform: translateX(2px)
				svg
					margin-left: 10px
					height: 15px
					transition: transform .35s ease
				&:after
					content: ''
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%

	@media(max-width: 768px)
		ul
			li
				flex-wrap: wrap
				h4, p, a
					width: 100%
				p, a
					margin-top: 14px
				a
					text-align: left
