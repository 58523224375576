@use 'common'

.wrapper
	width: 100%
	background-color: common.$yellow
	position: fixed
	bottom: 0
	left: 0
	padding: 20px
	box-sizing: border-box
	z-index: 10
	box-shadow: 0px -4px 39px -12px rgba(0,0,0,0.08)
	transition: opacity .35s ease
	@media(max-width: 768px)
		padding: 10px
	&.solved
		opacity: 0
		pointer-events: none

.inner
	color: #000
	position: relative

.close
	position: absolute
	top: 0
	right: 0
	width: 30px
	height: 30px
	padding: 0
	border: none
	background-color: transparent

.checkboxes
	display: flex
	flex-direction: column
	padding: 0

.checkbox
	font-size: 20px
	font-weight: 400
	font-family: common.$mager
	user-select: none
	cursor: pointer
	span
		font-size: 25px
		@media(max-width: 768px)
			font-size: 18px

.checkbox + .checkbox
	margin-top: 10px
	@media(max-width: 768px)
		margin-top: 5px

.input
	margin: 0 5px 0 0
	position: relative
	appearance: none
	width: 20px
	height: 20px
	border: 2px solid #000
	transform: translateY(1px)
	@media(max-width: 768px)
		transform: translateY(3px)
	&:disabled
		opacity: .6
		&::after
			opacity: 1
	&::after
		opacity: 0
		background: #000
		content: ""
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		-webkit-clip-path: polygon(14% 44%,0 65%,50% 100%,100% 16%,80% 0,43% 62%)
		clip-path: polygon(14% 44%,0 65%,50% 100%,100% 16%,80% 0,43% 62%)
		transition: opacity .35s ease
	&:checked
		&::after
			opacity: 1

.actions
	margin: 20px -10px 0 -10px
	@media(max-width: 768px)
		margin-top: 10px

.button
	background-color: #000
	color: #fff
	min-width: 250px
	padding: 20px 40px
	font-size: 20px
	box-sizing: border-box
	font-family: common.$regular
	text-transform: uppercase
	box-sizing: border-box
	display: inline-block
	text-align: center
	transition: all .35s ease
	border: none
	margin: 10px
	cursor: pointer
	@media(max-width: 768px)
		padding: 20px
		font-size: 18px

.description
	font-size: 16px
	color: #000
	margin: 5px 0 0 25px
	max-width: 900px
	@media(max-width: 768px)
		font-size: 14px
		line-height: 1.2em

.text
	font-size: 20px
	@media(max-width: 768px)
		font-size: 14px
		line-height: 1.2em

.title
	font-size: 32px
	@media(max-width: 768px)
		font-size: 25px
