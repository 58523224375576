@use 'common'

.section
	width: 100%
	&.viewRight
		.inner
			flex-direction: row-reverse
		.right
			margin-right: 0
			margin-left: -10px
		.bottom
			margin-left: 0
			margin-right: -10px
	&.viewBoth
		.bottom
			justify-content: flex-start
			margin-left: -10px
			width: calc(100% + 20px)

.inner
	display: flex
	justify-content: space-between
	flex-wrap: wrap
	align-items: flex-start
	@media(max-width: 768px)
		text-align: center
	.left
		width: calc(50% - 60px)
		flex-shrink: 0
		ul
			list-style: none
			padding: 0
			margin: 0
			display: flex
			li + li
				margin-left: 25px
		@media(max-width: 1100px)
			width: 100%
	.right
		width: calc(50% + 10px)
		flex-shrink: 0
		display: flex
		//margin: -10px
		flex-wrap: wrap
		margin-right: -10px
		> div:first-child
			margin-left: 0
		@media(max-width: 1100px)
			width: calc(100% + 20px)
			margin: 0 0 0 -10px
			justify-content: center
		@media(max-width: 768px)
			width: 100%
			margin: 0
		//margin-left: auto
	.bottom
		width: calc(100% + 20px)
		margin-left: -10px
		display: flex
		//margin: -10px
		align-items: flex-start
		justify-content: center
		flex-wrap: wrap
		flex-shrink: 0
		@media(max-width: 1100px)
			width: calc(100% + 20px)
			margin: 0 0 0 -10px
		@media(max-width: 768px)
			width: 100%!important
			margin: 0 auto
			margin-left: auto!important
			margin-right: auto!important
