@use 'common'

.container
	max-width: 1160px
	margin: 0 auto
	position: relative
	@media(max-width: 1150px)
		max-width: unset
		padding: 0 40px
	@media(max-width: 480px)
		padding: 0 15px
