@use 'common'

.button
	background-color: common.$yellow
	border: none
	position: absolute
	top: 50%
	transform: translate(-50%, -50%) scale(-1)
	z-index: 3
	cursor: pointer
	width: 58px
	height: 58px
	display: flex
	align-items: center
	justify-content: center
	@media(max-width: 768px)
		display: none

.next
	right: 0
	transform: translate(50%, -50%)

