@use 'common'

.wrapper
	width: 100%
	list-style: none
	padding: 0

.item
	position: relative
	&::before
		content: ''
		width: 12px
		height: 12px
		background-color: common.$yellow
		position: absolute
		top: 8px
		left: -30px
		font-size: 16px

.item + .item
	margin-top: 20px

.item + .collapsible
	margin-top: 20px

.button
	background-color: transparent
	padding: 0
	border: none
	display: flex
	color: #000
	text-transform: uppercase
	font-family: common.$regular
	font-size: 23px
	cursor: pointer
	transition: transform .35s ease
	span
		margin: 0 5px
	svg
		transition: transform .35s ease
		transform: rotate(90deg) translateX(3px)
	&:hover
		svg
			transform: rotate(90deg) translateX(5px)

.less
	svg
		transform: rotate(-90deg) translateX(-2px)
	&:hover
		svg
			transform: rotate(-90deg) translateX(1px)

@media(max-width: 768px)
	.item
		font-size: 16px
		&::before
			width: 8px
			height: 8px
			top: 8px
			left: -30px
	.item + .item
		margin-top: 5px
	.item + .collapsible
		margin-top: 5px

	.button
		font-size: 16px
		svg
			height: 13px
			width: auto
		svg
			transform: rotate(90deg) translateX(3px)
		&:hover
			svg
				transform: rotate(90deg) translateX(5px)

	.less
		svg
			transform: rotate(-90deg) translateX(-2px)
		&:hover
			svg
				transform: rotate(-90deg) translateX(1px)
