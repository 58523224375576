@use 'common'

.section
	width: 100%
	position: relative
	h2
		margin-bottom: 60px
		text-align: center
		color: #fff
	@media(max-width: 768px)
		h2
			margin-bottom: 40px

.bg
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	> div
		position: absolute!important
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover

.inner
	display: flex
	flex-wrap: wrap
	max-width: 800px
	margin: 0 auto
	> div
		width: calc(50% - 40px)
		padding-top: calc(50% - 40px)
		margin: 20px
	@media(max-width: 768px)
		width: calc(100% + 10px)
		margin: 0 -5px
		> div
			width: calc(50% - 10px)
			margin: 5px
			padding-top: 0
