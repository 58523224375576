@use 'common'

.btn
	background-color: common.$yellow
	min-width: 250px
	padding: 20px 40px
	font-size: 20px
	box-sizing: border-box
	font-family: common.$regular
	text-transform: uppercase
	color: #000
	box-sizing: border-box
	display: inline-block
	text-align: center
	transition: all .35s ease
	&.anchor.white
		background-color: #fff
		&:hover
			background-color: #000
	&.anchor, &.anchor.white
		svg
			transform: translateY(1px) rotate(90deg) !important
	svg
		margin-left: 10px
		transform: translateY(1px)
	&:hover
		background-color: #000
		color: #fff
	@media(max-width: 768px)
		padding: 20px
		font-size: 18px

.link
	text-decoration: underline
	&.anchor
		svg
			transform: translateY(1px) rotate(90deg)
		&:hover
			svg
				transform: translateY(3px) rotate(90deg)
