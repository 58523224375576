@use 'common'

.section
	width: 100%
	position: relative
	padding-top: 150px!important
	padding-bottom: 0!important
	width: 100%
	@media(max-width: 768px)
		padding-top: 0!important
		background-color: #fff!important

.header
	h1
		max-width: 500px
		text-transform: uppercase
		margin-bottom: 70px
		line-height: 1em
		color: #fff
	> div:first-child
		display: none!important
	@media(max-width: 768px)
		padding: 150px 0 50px 0
		margin-bottom: 50px
		position: relative
		h1
			margin-bottom: 0
		> div:first-child
			display: block!important
			position: absolute !important
			top: 0
			left: 0
			width: 100%
			height: 100%
			&:after
				content: ''
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				background: rgba(0, 0, 0, 0.2)
			> div
				position: absolute!important
				top: 0
				left: 0
				width: 100%
				height: 100%
				object-fit: cover

.bg
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	&:after
		content: ''
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: rgba(0, 0, 0, 0.2)
	> div
		position: absolute!important
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover
	@media(max-width: 768px)
		display: none

.inner
	display: flex
	flex-wrap: wrap
	//justify-content: space-between
	margin: 0 -11px
	@media(max-width: 600px)
		margin: 0

.box
	width: calc(25% - 22px)
	padding-top: calc(25% - 22px)
	position: relative
	background-color: common.$grey
	margin: 0 11px 0 11px
	&:first-child
		background-color: common.$yellow
	@media(max-width: 1100px)
		width: calc(50% - 22px)
		padding-top: calc(50% - 22px)
		//margin: 0
		&:nth-child(n + 3)
			margin-top: 44px
	@media(max-width: 600px)
		width: 100%
		padding-top: 100%
		margin-bottom: 20px
		&:nth-child(n + 3)
			margin-bottom: 20px
			margin-top: 0
		&:last-child
			margin-bottom: 50px

.boxInner
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	padding: 25px
	box-sizing: border-box
	display: flex
	flex-direction: column
	justify-content: flex-end
	> a
		font-family: common.$bold
		text-transform: uppercase
		font-size: 20px
		text-decoration: none
		&:hover
			svg
				transform: translateX(2px)
		svg
			margin-left: 10px
			height: 15px
			transition: transform .35s ease
	article, p
		margin: 0
		font-size: 20px
	h3
		margin-bottom: auto
		justify-self: flex-start
		font-size: 25px
		font-family: common.$regular
	ul
		padding: 0
		list-style: none
		display: flex
		align-items: center
		margin: 0
		li
			margin-right: 10px
			a
				display: block
				background-color: common.$yellow
				border-radius: 50%
				width: 35px
				height: 35px
				display: flex
				align-items: center
				justify-content: center
				svg
					width: 17px
					height: 17px
