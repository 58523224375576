@use 'common'

.section
	width: 100%
	background-color: common.$yellow
	border-top: 2px solid #000
	padding-bottom: 50px!important
	@media(prefers-color-scheme: dark)
		background-color: common.$yellow
	@media(max-width: 768px)
		padding-bottom: 20px!important

.inner
	display: flex
	flex-wrap: wrap

.logo
	color: #000
	margin-right: 50px
	margin-bottom: 40px
	svg
		width: 190px
		height: 43px

.socials
	padding: 0
	list-style: none
	display: flex
	align-items: center
	margin: 0 50px 40px 0
	align-items: flex-start
	li + li
		margin-left: 30px
	li
		a
			display: block
			width: 20px
			height: 20px
			color: #000
			svg
				width: 20px
				height: 20px

.menu
	padding: 0
	list-style: none
	margin: 0 50px 40px 0
	li
		button
			padding: 0
			background-color: transparent
			border: none
		a, button
			font-size: 20px
			text-decoration: none
			font-family: common.$mager
			color: #000
			cursor: pointer
	li + li
			margin-top: 15px

.link
	color: #000
	text-decoration: none
	font-family: common.$bold
	color: #000
	font-size: 20px

.small
	display: flex
	justify-content: space-between
	margin-top: 50px
	a
		text-decoration: underline
		&:hover
			text-decoration: none
	a, small
		font-size: 15px
		color: #000
		font-family: common.$mager
