@use 'common'

.section
	width: 100%
	h2, article
		max-width: 600px

.inner
	margin-top: 60px
	ul
		list-style: none
		padding: 0
		margin: 0
		width: 100%
		border-top: 1px solid #000
		li
			display: flex
			align-items: flex-start
			width: 100%
			justify-content: space-between
			padding: 15px 10px
			border-bottom: 1px solid #000
			position: relative
			&:hover
				background-color: #e2e2e2
			h4, p, a
				width: calc((100% / 3) - 20px)
				font-size: 20px
				font-family: common.$mager
				margin: 0
			p
				opacity: .5
			a
				text-align: right
				cursor: pointer
				&:after
					content: ''
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%

	@media(max-width: 768px)
		margin-top: 40px
		ul
			li
				flex-wrap: wrap
				h4, p, a
					width: 100%
				p, a
					margin-top: 14px
				a
					text-align: left
