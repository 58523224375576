@use 'common'

.carousel
	display: block
	position: relative
	width: 30%
	padding-top: 30%
	position: relative
	flex-shrink: 0
	margin-left: 40px

	.image
		position: absolute!important
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover
		opacity: 0
		transition: opacity .35s ease
		> div
			position: absolute!important
			top: 0
			left: 0
			width: 100%
			height: 100%
			object-fit: cover
		&.active
			opacity: 1

	@media(max-width: 768px)
		width: 100%
		padding-top: 100%
		margin: 0
