@use 'common'

.section
	width: 100%
	h2
		margin-bottom: 30px
	@media(max-width: 768px)
		h2
			//margin-bottom: 40px

.inner
	display: flex
	align-items: center
	justify-content: space-between
	@media(max-width: 768px)
		flex-direction: column

.content
	width: 55%
	a
		margin: 0 20px 20px 0
	@media(max-width: 768px)
		width: 100%
		h2
			text-align: center
			margin-bottom: 25px

.gallery
	width: 40%
	display: flex
	flex-wrap: wrap
	max-width: 500px
	margin: 0 -10px
	.top
		width: 100%
		padding-top: 100%
		position: relative
		margin: 10px
		margin-bottom: 0
		.image
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			object-fit: cover
			opacity: 0
			transition: opacity .35s ease
			margin: 0
			&.active
				opacity: 1
				display: block
	.image
		width: calc((100% / 3) - 20px)
		margin: 20px 10px 0 10px
		padding-top: calc((100% / 3) - 20px)
		position: relative
		&.active
			display: none

		> div
			position: absolute!important
			top: 0
			left: 0
			width: 100%
			height: 100%
			object-fit: cover
	@media(max-width: 768px)
		width: 100%
		max-width: unset
		margin: 0 -10px
		width: calc(100% + 20px)
