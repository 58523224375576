@use 'common'

.section
	width: 100%
	h2
		text-align: center
	article
		max-width: 700px
		margin: 0 auto
		text-align: center

.info
	position: absolute
	z-index: 5
	transform: translate(-50%, -50%)
	background-color: #000
	color: #fff
	padding: 15px
	text-align: left
	max-width: 280px
	width: 100%
	box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.29)
	button
		padding: 0
		background-color: transparent
		border: none
		position: absolute
		top: 10px
		right: 10px
		width: 15px
		height: 15px
		cursor: pointer
		z-index: 1
		svg
			width: 15px
			height: 15px
			color: #fff
	ul
		margin: 0
		list-style: none
		text-align: left
		padding: 0
		li + li
			margin-top: 15px
		li
			padding-left: 10px
			position: relative
			font-size: 18px
			color: #fff
			line-height: 1.3em
			&:before
				content: ''
				position: absolute
				top: 0
				left: 0
				height: 100%
				width: 1px
				background-color: common.$yellow

.inner
	margin-top: 60px
	position: relative
	@media(max-width: 768px)
		margin-top: 40px

.innerScrollable
	overflow-x: scroll
	scrollbar-color: common.$yellow #000
	padding-bottom: 10px
	&::-webkit-scrollbar
		height: 5px
		-webkit-appearance: none
		width: 100%
		-webkit-overflow-scrolling: auto
	&::-webkit-scrollbar-track
		background-color: common.$yellow
		height: 5px !important
	&::-webkit-scrollbar-thumb
		background-color: #000

.button
	font-size: 20px
	font-family: common.$regular
	display: flex
	align-items: center
	padding: 0
	border: none
	background-color: transparent
	margin-top: 50px
	cursor: pointer
	color: #000
	&.less
		svg
			transform: translateY(-1px) rotate(-90deg)
	svg
		width: 15px
		transform: translateY(-1px) rotate(90deg)
		margin-left: 5px

.chain
	display: flex
	//overflow-x: scroll
	height: 100px
	position: relative
	//border-bottom: 5px solid #fff
	// &:after
	// 	content: ''
	// 	position: sticky
	// 	bottom: 0
	// 	left: 0
	// 	width: 100%
	// 	height: 9px
	// 	background-color: red
	// 	z-index: 3
	&::-webkit-scrollbar
		display: none
	&.hide
		display: none

.chain + .chain
	margin-top: 50px
	@media(max-width: 768px)
		margin-top: 30px

.chain + .collapsible
	margin-top: 50px
	@media(max-width: 768px)
		margin-top: 30px

.single
	background-color: common.$yellow
	width: 250px
	height: 92px
	padding: 10px 20px
	box-sizing: border-box
	display: flex
	align-items: center
	justify-content: center
	flex-shrink: 0
	//margin-right: 10px
	position: relative
	user-select: none
	cursor: pointer
	text-align: center
	&:hover
		background-color: #000
		color: #fff
		p
			text-decoration: underline
		&:after, &:before
			background-color: #000
	&:after
		content: ''
		position: absolute
		right: -45px
		height: 65px
		width: 65px
		background-color: common.$yellow
		transform: translate3d(0, 0, 0) rotate(135deg)
		transform-origin: center
		border-top: 10px solid #fff
		border-left: 10px solid #fff
		//border-bottom-color: common.$yellow
		//border-right-color: common.$yellow
		z-index: 1
		//box-sizing: border-box
	&:before
		content: ''
		position: absolute
		right: 0
		height: 100%
		width: 46px
		background-color: common.$yellow
		z-index: 2
	&:first-child
		p
			padding-left: 0

	p
		font-size: 20px
		font-family: common.$mager
		font-weight: 700
		margin: 0
		text-align: center
		z-index: 3
		padding: 0 0 0 50px
		transform: translateX(5px)

	@media(max-width: 768px)
		width: 200px
		height: 92px
		padding: 10px 20px
		p
			font-size: 18px
