@use 'common'

.video
	display: block
	z-index: 1
	width: 100%
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	video
		width: 100%
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover
	button
		z-index: 2
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		background-color: common.$yellow
		display: block
		width: 60px
		height: 60px
		display: flex
		align-items: center
		justify-content: center
		transition: all .35s ease
		border: none
		cursor: pointer
		&.playing
			top: calc(100% - 10px)
			left: 10px
			transform: translate(0, -100%)
			&:hover
				transform: translate(0, -100%)
		svg
			width: 30px
			height: 30px
		&:hover
			transform: translate(-50%, -50%) scale(.9)

	@media(max-width: 768px)
		button
			width: 40px
			height: 40px
			svg
				width: 20px
				height: 20px
