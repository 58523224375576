@use 'common'

.section
	width: 100%
	background-color: #fff!important
	h2
		text-align: center
		margin-bottom: 60px
	@media(max-width: 768px)
		h2
			margin-bottom: 40px

.inner
	width: 100%

.nav
	display: flex
	list-style: none
	padding: 0
	margin: 0
	@media(max-width: 768px)
		overflow-x: scroll
		&::-webkit-scrollbar
			display: none
	li
		width: 20%
		padding: 30px
		box-sizing: border-box
		position: relative
		background-color: #fff
		&.active
			background-color: common.$grey
			svg
				transform: translateY(-2px)
		button
			width: 100%
			display: block
			font-size: 60px
			font-family: common.$bold
			cursor: pointer
			border: none
			padding: 0
			text-align: left
			background-color: transparent
			color: #000
			&:after
				content: ''
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
		span
			font-size: 20px
			margin-right: 10px
		svg
			transform: translateY(-2px) rotate(-90deg)
			transition: transform .35s ease
		@media(max-width: 768px)
			padding: 20px
			width: 170px
			flex-shrink: 0
			button
				font-size: 35px
			span
				font-size: 14px
				margin-right: 5px
			svg
				width: 12px
				transform: translateY(1px)

.single
	display: none
	align-items: flex-start
	justify-content: space-between
	&.active
		display: block
		display: flex
	article
		max-width: 600px
		a
			display: inline-block
			margin-top: 2em
			font-family: common.$bold
			text-decoration: none
			font-size: 20px
			text-transform: uppercase
			&:hover
				svg
					transform: translateX(2px)
			svg
				margin-left: 10px
				transition: transform .35s ease
	.image
		width: 30%
		padding-top: 30%
		position: relative
		flex-shrink: 0
		margin-left: 40px
		> div
			position: absolute!important
			top: 0
			left: 0
			width: 100%
			height: 100%
			object-fit: cover
	@media(max-width: 768px)
		flex-wrap: wrap
		.image
			width: 100%
			padding-top: 100%
			margin: 0

.content
	background-color: common.$grey
	padding: 30px
	box-sizing: border-box
	@media(max-width: 768px)
		padding: 20px
