@use 'common'

.section
	width: 100%
	h2
		margin-bottom: 60px
		text-align: center
	@media(max-width: 768px)
		h2
			margin-bottom: 40px

.inner
	display: flex
	flex-wrap: wrap

.single
	width: calc((100% / 3) - 100px)
	margin: 20px 50px
	display: flex
	> span
		flex-shrink: 0
		width: 45px
		height: 45px
		background-color: common.$yellow
		display: flex
		align-items: center
		justify-content: center
		font-size: 30px
		font-family: common.$bold
	article
		flex-shrink: 0
		margin-left: 20px
		width: calc(100% - 65px)
		font-size: 20px
		p
			font-size: 20px
			br
				margin-top: .5em
				content: " "
				line-height: 1em
				display: block
		h3
			font-size: 30px
			font-family: common.$bold
			line-height: 1
			text-transform: uppercase
			min-height: 2em
	@media(max-width: 992px)
		width: calc(50% - 100px)
	@media(max-width: 768px)
		width: 100%
		margin: 0 0 15px 0
		&:last-child
			margin-bottom: 0
		article
			p
				display: none
			h3
				font-size: 20px
